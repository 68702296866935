<template>
  <div class="aiz-user-panel">
    
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <b class="h4">{{ $t('yuan-wang-qing-dan') }}</b>
        </div>
      </div>
    </div>

    <div class="row gutters-5">
      
      <div class="col-xxl-3 col-xl-4 col-lg-3 col-md-4 col-sm-6" v-for="(item, i) in wishList" :key="i">
        <div class="card mb-2 shadow-sm">
          <div class="card-body">
            <a class="d-block mb-3">
              <img :src="item.Goods.ImgUrl" class="img-fit h-140px h-md-200px">
            </a>

            <h5 class="fs-14 mb-0 lh-1-5 fw-600 text-truncate-2">
              <a  class="text-reset">{{item.Goods.GoodsName }}</a>
            </h5>
            <div class="rating rating-sm mb-1">
              <i class="las la-star active" v-for="m in item.star" :key="m"></i>
              <i class="las la-star" v-for="n in 5" :key="n" v-if="n > item.star"></i>
            </div>
            <div class=" fs-14">
              <!-- <del class="opacity-60 mr-1">199</del> -->
              <span class="fw-600 text-primary">{{resetPrice(item.Goods.Price)}}</span>
            </div>
          </div>
          <div class="card-footer">
            <a href="javascript:void(0)" class="link link--style-3" @click="removeWish(item)">
              <i class="la la-trash la-2x"></i>
            </a>
            <button type="button" class="btn btn-sm btn-block btn-primary ml-3" @click="toCart(item)">
              <i class="la la-shopping-cart mr-2"></i>{{ $t('tian-jia-dao-gou-wu-che') }}
            </button>
          </div>
        </div>
      </div>
                
      <div class="col" v-if="wishList.length == 0">
        <div class="text-center bg-white p-4 rounded shadow">
          <img class="mw-100 h-200px" :src="emptyUrl" >
          <h5 class="mb-0 h5 mt-3">{{ $t('huan-mei-you-tian-jia-ren-he-dong-xi') }}</h5>
        </div>
      </div>
    </div>
    <!-- <div class="aiz-pagination mt-4" v-show="wishList.length > 0">
      <pageBar :totalPage="56" :page="51"></pageBar>
    </div> -->
  </div>
</template>
<script>
import pageBar from '@/components/page'
import {Popup} from 'vant'
import { cardRemove, getCartList, addCart  } from '@/api/index'
import { resetPrice } from '@/utils/common'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      emptyUrl: require('../../assets/imgs/empty.svg'),
      showPopup: false,
    }
  },
  computed: {
    wishList() {
      return this.$store.state.wishList
    }
  },
  mounted() {

  },
  methods: {
    resetPrice,
    removeWish(data) {
      let form = new FormData()
      form.append('ID', data.ID)
      cardRemove(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('yi-chu-cheng-gong'))
          this.initCart()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    initCart() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      getCartList(form).then(res => {
				this.$store.state.cartList = res.data.Items.filter(v => {
          return v.Attribute == 1
        })
				this.$store.state.wishList = res.data.Items.filter(v => {
          return v.Attribute == 2
        })
			})
    },
    toCart(data) {
      let form = new FormData()
      form.append('GoodsId', data.ID)
      form.append('Attribute', 1)
      addCart(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('tian-jia-cheng-gong'))
          this.initCart()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>